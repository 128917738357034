var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"warper"},[_c('div',{staticClass:"navigationBox"},[_vm._m(0),_c('div',{staticClass:"navRight"},_vm._l((_vm.navList),function(navItem,index){return _c('a',{key:index,staticClass:"navListItem",attrs:{"href":navItem.id}},[_vm._v(_vm._s(navItem.name))])}),0)]),_vm._m(1)])]),_vm._m(2),_c('div',{staticClass:"ppgsBox",attrs:{"id":"ppgs"}}),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navLeft"},[_c('img',{attrs:{"src":require("./assets/logo.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topBgBox"},[_c('div',{staticClass:"topBgLeft"},[_c('div',{staticClass:"tilte"},[_vm._v("比分体育")]),_c('div',{staticClass:"tilte"},[_vm._v(" 是一款专注于足球赛事分析的产品！")]),_c('div',{staticClass:"introduce"},[_vm._v("汇聚众多业内体育专家，通过多年从业经验，对各大联赛热门赛事进行全方位、多层次的独家分析，帮助用户进行参考决策。")])]),_c('div',{staticClass:"topBgRight"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xuanchuanBox"},[_c('div',{staticClass:"xcItem"},[_c('div',{staticClass:"xcLeft"},[_c('img',{attrs:{"src":require("./assets/fgdqIcon.png"),"alt":""}})]),_c('div',{staticClass:"xcRight"},[_c('span',{staticClass:"xcRightnum"},[_vm._v("20+")]),_c('span',{staticClass:"xcRightText"},[_vm._v("覆盖地区(个)")])])]),_c('div',{staticClass:"xcItem"},[_c('div',{staticClass:"xcLeft"},[_c('img',{attrs:{"src":require("./assets/ljfwjgIcon.png"),"alt":""}})]),_c('div',{staticClass:"xcRight"},[_c('span',{staticClass:"xcRightnum"},[_vm._v("200+")]),_c('span',{staticClass:"xcRightText"},[_vm._v("累计服务机构(家)")])])]),_c('div',{staticClass:"xcItem"},[_c('div',{staticClass:"xcLeft"},[_c('img',{attrs:{"src":require("./assets/ljfwrsIcon.png"),"alt":""}})]),_c('div',{staticClass:"xcRight"},[_c('span',{staticClass:"xcRightnum"},[_vm._v("99,124,121")]),_c('span',{staticClass:"xcRightText"},[_vm._v("累计服务客户(人)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zyfwBox",attrs:{"id":"zyfw"}},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"titleText"},[_vm._v("专业服务")]),_c('div',{staticClass:"titleLine"})]),_c('div',{staticClass:"main"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gywmBox",attrs:{"id":"gywm"}},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"titleText"},[_vm._v("关于我们")]),_c('div',{staticClass:"titleLine"})]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"mainTop"},[_c('div',{staticClass:"gywoBg"}),_c('div',{staticClass:"gywmText"},[_vm._v(" 比分体育是一家专注于提供内容丰富的体育大数据服务、体育资讯服务及其周边产品的公司。 满足广大体育爱好者对于体育赛事即时比分、比赛直播、实时指数、赛事资料与情报、赛程赛果、实时指数等专业体育数据服务的需求， 同时还扮演为体育爱好者提供交流互动平台的角色，为中国体育事业的发展尽一份力。 ")])]),_c('div',{staticClass:"mainBottom"},[_c('div',{staticClass:"mainBottomItem"},[_c('div',{staticClass:"itemTitle"},[_c('div',{staticClass:"titleLine"}),_c('div',{staticClass:"titleText"},[_vm._v("专业服务团队")])]),_c('div',{staticClass:"text"},[_vm._v("这里汇聚了一支充满激情、专业且富有创新精神的团队。我们致力于为广大体育爱好者提供全面、准确、及时的体育资讯和赛事服务。")])]),_c('div',{staticClass:"mainBottomItem"},[_c('div',{staticClass:"itemTitle"},[_c('div',{staticClass:"titleLine"}),_c('div',{staticClass:"titleText"},[_vm._v("产品研发团队")])]),_c('div',{staticClass:"text"},[_vm._v("我们的体育官网产品研发团队是一支充满活力、富有创新精神的专业队伍。团队成员由资深的软件工程师、用户体验设计师、数据分析师 和产品经理组成，他们共同致力于为用户打造卓越、高效且充满乐趣的在线体育体验。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_vm._v(" 杭州影火体育文化有限公司 "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn"}},[_vm._v("浙ICP备2024100055号-1")])])
}]

export { render, staticRenderFns }