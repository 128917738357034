<template>
  <div id="app">
    <div class="header">
      <div class="warper">
        <div class="navigationBox">
          <div class="navLeft">
           <img src="./assets/logo.svg" alt="">
          </div>
          <div class="navRight">
            <a :href="navItem.id" class="navListItem" v-for="navItem, index in navList" :key="index">{{ navItem.name }}</a>
          </div>
        </div>
        <div class="topBgBox">
          <div class="topBgLeft">
            <div class="tilte">比分体育</div>
            <div class="tilte">
              是一款专注于足球赛事分析的产品！</div>
            <div class="introduce">汇聚众多业内体育专家，通过多年从业经验，对各大联赛热门赛事进行全方位、多层次的独家分析，帮助用户进行参考决策。</div>
          </div>
          <div class="topBgRight">

          </div>
        </div>

      </div>
    </div>
    <div class="xuanchuanBox">
      <div class="xcItem">
        <div class="xcLeft">
          <img src="./assets/fgdqIcon.png" alt="">
        </div>
        <div class="xcRight">
          <span class="xcRightnum">20+</span>
          <span class="xcRightText">覆盖地区(个)</span>
        </div>
      </div>
      <div class="xcItem">
        <div class="xcLeft">
          <img src="./assets/ljfwjgIcon.png" alt="">
        </div>
        <div class="xcRight">
          <span class="xcRightnum">200+</span>
          <span class="xcRightText">累计服务机构(家)</span>
        </div>
      </div>
      <div class="xcItem">
        <div class="xcLeft">
          <img src="./assets/ljfwrsIcon.png" alt="">
        </div>
        <div class="xcRight">
          <span class="xcRightnum">99,124,121</span>
          <span class="xcRightText">累计服务客户(人)</span>
        </div>
      </div>
    </div>
    <div class="ppgsBox" id="ppgs"></div>
    <div class="zyfwBox" id="zyfw">
      <div class="title">
        <div class="titleText">专业服务</div>
        <div class="titleLine"></div>
      </div>
      <div class="main"></div>
    </div>
    <div class="gywmBox" id="gywm">
      <div class="title">
        <div class="titleText">关于我们</div>
        <div class="titleLine"></div>
      </div>
      <div class="main">
        <div class="mainTop">
          <div class="gywoBg"></div>
          <div class="gywmText">
            比分体育是一家专注于提供内容丰富的体育大数据服务、体育资讯服务及其周边产品的公司。
            满足广大体育爱好者对于体育赛事即时比分、比赛直播、实时指数、赛事资料与情报、赛程赛果、实时指数等专业体育数据服务的需求，
            同时还扮演为体育爱好者提供交流互动平台的角色，为中国体育事业的发展尽一份力。
          </div>
        </div>
        <div class="mainBottom">
          <div class="mainBottomItem">
            <div class="itemTitle">
              <div class="titleLine"></div>
              <div class="titleText">专业服务团队</div>
            </div>
            <div class="text">这里汇聚了一支充满激情、专业且富有创新精神的团队。我们致力于为广大体育爱好者提供全面、准确、及时的体育资讯和赛事服务。</div>
          </div>
          <div class="mainBottomItem">
            <div class="itemTitle">
              <div class="titleLine"></div>
              <div class="titleText">产品研发团队</div>
            </div>
            <div class="text">我们的体育官网产品研发团队是一支充满活力、富有创新精神的专业队伍。团队成员由资深的软件工程师、用户体验设计师、数据分析师
              和产品经理组成，他们共同致力于为用户打造卓越、高效且充满乐趣的在线体育体验。</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="downloadBox" id="download"></div> -->
    <div class="footer">
      杭州影火体育文化有限公司 <a href="https://beian.miit.gov.cn">浙ICP备2024100055号-1</a>
    </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  data() {
    return {
      navList: [{
        name: '首页',
        id:'#'
      }, {
        name: '品牌故事',
        id:'#ppgs'
      }, {
        name: '专业服务',
        id:'#zyfw'
      }, {
        name: '关于我们',
        id:'#gywm'
      }, 
      // {
      //   name: '下载App',
      //   id:'#download'
      // }
      ]

    }
  }

}
</script>

<style lang="less" scoped>
a {
  text-decoration: none;
}

.header {
  width: 100%;
  height: 750px;
  box-sizing: border-box;
  padding: 0 240px;
  background-image: url(./assets/headerRightbg.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 995px 750px;

  .warper {
    margin: 0 auto;
    height: 64px;

    .navigationBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      flex-direction: row;

      .navLeft {
        
        width: 217px;
        height: 64px;
        img{
         width: 100%;
         height: 100%;
        }
      }

      .navRight {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: nowrap;

        .navListItem {
          width: 136px;
          height: 64px;
          line-height: 64px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
          text-align: center;
          font-style: normal;
          text-transform: none;
        }
      }
    }

    .topBgBox {
      width: 100%;
      height: 658px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      flex-direction: row;

      .topBgLeft {
        .tilte {
          font-weight: bold;
          font-size: 56px;
          color: #354671;
          line-height: 81px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }

        .introduce {
          margin-top: 38px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #354671;
          line-height: 48px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .topBgRight {
        width: 664px;
        height: 100%;
        background: url(./assets/headerImg.png) no-repeat;
        background-size: 664px 658px;
      }
    }


  }
}

.xuanchuanBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 241px;
  box-sizing: border-box;
  padding: 0 240px;

  .xcItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .xcLeft {
      img {
        width: 67px;
        height: 67px;
      }
    }

    .xcRight {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      margin-left: 30px;

      .xcRightnum {
        font-weight: 400;
        font-size: 40px;
        color: #354671;
        line-height: 47px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .xcRightText {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #354671;
        line-height: 28px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}

.zyfwBox {

  width: 100%;
  height: 919px;

  .title {
    height: 174px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .titleText {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 36px;
      color: #354671;
      line-height: 52px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      z-index: 99;
    }

    .titleLine {
      width: 157px;
      height: 16px;
      background: linear-gradient(180deg, #323DFF 0%, #459BFF 100%);
      border-radius: 24px 24px 24px 24px;
      position: relative;
      top: -14px;
    }
  }

  .main {
    width: 100%;
    height: 745px;
    background-image: url(./assets/zyfwbg.png);
    background-repeat: no-repeat;

    background-size: 100% 100%;
  }
}

.ppgsBox {
  width: 100%;
  height: 500px;
  background-image: url(./assets/ppgsbg.png);
  background-repeat: no-repeat;

  background-size: 100% 100%;
}
.downloadBox{
  width: 100%;
  height: 750px;
  background-image: url(./assets/download.png);
  background-repeat: no-repeat;

  background-size: 100% 100%;
}
.gywmBox {
  width: 100%;
  height: 1213px;
  box-sizing: border-box;
  padding: 0 240px;

  .title {
    height: 174px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .titleText {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 36px;
      color: #354671;
      line-height: 52px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      z-index: 99;
    }

    .titleLine {
      width: 157px;
      height: 16px;
      background: linear-gradient(180deg, #323DFF 0%, #459BFF 100%);
      border-radius: 24px 24px 24px 24px;
      position: relative;
      top: -14px;
    }
  }

  .main {
    .mainTop {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;

      .gywoBg {
        width: 800px;
        height: 546px;
        background-image: url(./assets/gywmbg.png);
        background-repeat: no-repeat;

        background-size: 100% 100%;
      }

      .gywmText {

        width: 647px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #000000;
        line-height: 35px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-left: 20px;
      }
    }

    .mainBottom {
      margin-bottom: 60px;

      .mainBottomItem {
        margin-top: 60px;

        .itemTitle {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;

          .titleLine {
            width: 88px;
            height: 88px;
            background: linear-gradient(360deg, rgba(69, 155, 255, 0.5) 0%, #FFFFFF 100%);
            border-radius: 50%;
          }

          .titleText {
            font-family: PingFang SC, PingFang SC;
            font-weight: bold;
            font-size: 24px;
            color: #354671;
            line-height: 35px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            position: relative;
            left: -40px;
          }
        }

        .text {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 24px;
          color: #354671;
          line-height: 35px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-top: 16px;
        }
      }
    }

  }
}

.footer {
  width: 100%;
  height: 80px;
  background: #314659;
  line-height: 80px;
  font-family: PingFang SC, PingFang SC;
font-weight: 400;
font-size: 18px;
color: #FFFFFF;
text-align: left;
font-style: normal;
text-transform: none;
text-align: center;
a{
  color: #FFFFFF;
  margin-left: 16px;
}
}
</style>
